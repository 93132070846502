import React from 'react'
import Intro from '../../../components/Intro';
import Section from '../../../components/Section';
import ImgFit from '../../../components/ImgFit';
import Back from '../../../components/Back';
import ImgSmall from '../../../components/ImgSmall';
import ImgFull from '../../../components/ImgFull';
import Pagination from '../../../components/Pagination';
import Title1 from '../../../components/Title1';
import Flex from '../../../components/Flex';
import Helmet from 'react-helmet';
import Logo from './logo-paramount.svg';
import styles from '../../../styles/styles.json';
import Layout from "../../../components/layoutProject"
import SEO from "../../../components/seo"

export const frontmatter = {
  id: 0,
  isWork: true,
  url: 'paramount',
  title: 'Paramount',
  subtitle: 'Identity Recognition Platform',
  path: '/work/paramount',
  excerpt: `Identity Recognition Platform`,
};

const ParamountPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <Back />
      <div className="notch">
        <Helmet title={`${frontmatter.title} | ${data.site.siteMetadata.title}`} />
        <Intro dark full bg={styles.colors.paramount.base} >
          <img className="brand" src={Logo} alt={frontmatter.title} />
          <p className='highlight'>An application for movie lovers.</p>
          <p>The entire Paramount Channel catalogue from a mobile application easy to use on the go, scalable and maintainable from a management point of view.</p>
        </Intro>

        <Section dark full bg={styles.colors.paramount.base}>
          <Title1 title='Mobile application' />
          <p>Paramount Channel wanted a personalised experience for their audience. Consumers were overloaded with too many choices; the mobile app would solve that problem with custom notifications, rating and curated content.</p>
          <Flex>
            <ImgFit img={data.screenIntro.childImageSharp.fluid.src} style="rounded borderdark" />
            <ImgFit img={data.screenMovie.childImageSharp.fluid.src} style="rounded borderdark" />
          </Flex>
        </Section>

        <Section dark bg='#051C3B'>
          <Title1 title='Access to all movies' />
          <p>The list of films is one of the key features of the app. The grid is responsive, adapting the content to tablets and phones. We also provided dynamic images automatically scaling resolution and size to reduce data usage.</p>
          <ImgFull img={data.movies.childImageSharp.fluid.src} alt='Nirovision symbol' />
        </Section>

        <Section dark full bg={styles.colors.paramount.base}>
          <Title1 title='Movie catalogue' />
          <p>The discovery experience would give users recommendations based on their preferences and interactions. Relevant and live content helps users to engage with the application without being intrusive only notifying them on demand.</p>
          <ImgSmall img={data.screenList.childImageSharp.fluid.src} style="rounded borderdark" />
        </Section>

      </div>
      <Pagination currentproject={frontmatter.id} mydata={data} />
    </Layout>
  )
};

export default ParamountPage;

export const query = graphql`
  query GatsbyParamountQuery {
    site {
      siteMetadata {
        title
      }
    }

    allJavascriptFrontmatter(filter: {frontmatter: {path: {regex: "/work/"}}}, sort: {fields: [frontmatter___id], order: ASC}) {
      edges {
        node {
          frontmatter {
            isWork
            path
            id
            title
            url
          }
        }
      }
    }

    screenIntro: file(relativePath: { eq: "work/paramount/screen-intro.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2600) {
          src
        }
      }
    }
    screenMovie: file(relativePath: { eq: "work/paramount/screen-movie.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2600) {
          src
        }
      }
    }
    screenList: file(relativePath: { eq: "work/paramount/screen-list.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2600) {
          src
        }
      }
    }
    movies: file(relativePath: { eq: "work/paramount/movies-highlights.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2600) {
          src
        }
      }
    }
  
  }
`;